import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Pages/Home';
import HomeTrial from './Pages/HomeTrial';
import Ceo from './Pages/Ceo';
import Mission from './Components/Mission';
import AboutUs from './Components/aboutUs';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Pdf from './Components/PdfViewer';
import Business from './Pages/Business';
import Geospatial from './Components/index/Geospatial';
import Aml from './Components/index/Aml';
import RetailData from './Components/index/Geospatial/RetailData';
import RetailAnalytics from './Components/index/Geospatial/RetailAnalytics';
import Admin from './admin/adminPage';
import Login from './admin/LoginPage';
import Register from './admin/RegisterPage';
import ContactUs from './Components/index/ContactUs';
import Banking from './Pages/Banking/Banking';
import BTrial from './Pages/Banking_Trial/Banking';
import Analytics from './Pages/Analytics/Analytics';
import Feature from './Pages/Banking/Feature';
import RiskComplianceSub from './Pages/RiskCompliance/Feature';
import Healthcare from './Pages/Healthcare/Healthcare';
import Career from './Pages/Career/Career';
import JobDescriptionPage from './Pages/Career/JobDescriptionPage';
import CVS from './Pages/Cvs/CVS';
import NotFound from './Pages/notFound';
import Academy from './Pages/Academy/Academy';
import Logo from './Pages/TheLogo/TheLogo';
import Gov from './Pages/Government/Gov';
import Bi from './Pages/Bi/Bi';
import AI from './Components/Ai';
import DareToOvercome from './Components/DareToOvercome';
import Clients from './Components/ClientsPage/ClientPage';
import Multimedia from './Components/Multimedia';
import MindMap from './Components/MindMap/MindMap';
import CaseManagements from './Components/Geospatial/CaseManagements.jsx'
// import CaseManagements from './components/Geospatial/CaseManagements.jsx';
import CaseGeospatial from './Components/Geospatial/CaseGeospatial.jsx';
// import CEO from './Components/AboutUs/CEO/CEO.jsx';
import CaseAnalytics from './Components/Geospatial/CaseAnalytics.jsx';
import CaseBiometrics from './Components/Geospatial/CaseBiometrics.jsx';
import CaseRiskAndThreatManagement from './Components/Geospatial/CaseRiskAndThreatManagement.jsx';
import CaseKYC from './Components/Geospatial/CaseKYC.jsx';
import EmployeeDetails from './Employees/Employees.jsx';
// import Employment from './php/Employment.php'
import './App.css';
// import './responsive.scss';
import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import AnalyticsDisplay from './Pages/Analytics/AnalyticsPages/AnalyticsDisplay';
import InProfit from './Pages/inProfit/Feature';
import Marketing from './Pages/inMarketing/Feature';
import InAnalytics from './Pages/inAnalytics/Feature';
import InKernel from './Pages/inKernel/Feature';
import InAsset from './Pages/inAsset/Feature';
import GeospatialI from './Components/Geospatial/GeospatialI';
import Digital from './Components/Digital/Digital.jsx';
import Insurance from './Components/Insurance/insurance.jsx'
import Overview from './Components/Insurance/Overview.jsx';
import InDataA from './Pages/inData/Feature';
import NewHome from './ValooresNew/views/Home.jsx'
import Industries from './ValooresNew/views/Industries.jsx';
import Menu from './ValooresNew/includes/menu/Menu.jsx';
import FooterNew from './ValooresNew/includes/footer/footer.jsx';
import Solutions from './ValooresNew/views/Solutions.jsx';
import Consultancy from './ValooresNew/views/Consultancy.jsx';
import VFDS from './ValooresNew/views/VFDS.jsx';
import Vtech from './ValooresNew/views/Vtech.jsx';
import NVtech from './Components/VTECH/NewVtech.jsx';
import AboutUsNew from './ValooresNew/views/Overview.jsx';
import Test from './Components/index/Test.jsx';
import NewsEvents from './ValooresNew/views/NewsEvents.jsx';
import CEO from './ValooresNew/views/Ceo.jsx';
import PressRelease from './Pages/PressRelease/PressRelease.jsx';
import ServicesGroup from './Components/servicesGroup/ServicesGroup.jsx';
import Fatf from './Pages/Fatf/Fatf';
import Vcms from './Pages/Vcms/Vcms.jsx';
// import ValooresFramework from './ValooresNew/views/ValooresFramework.jsx';
import V21 from './Components/V21/V21.jsx';
import AboutValoores from './Components/AboutUs/AboutUs.jsx';
import TermsAndConditions from './Components/TermsAndConditions.jsx';
import PrivacyPolicy from './Components/PrivacyPolicy.jsx';
import Telecommunication from './Components/Telecommunication/Telecommunication.jsx';
import TelecommunicationTest from './Components/Telecommunication/TelecommunicatonTest.jsx';
import VCIS from './Components/VCIS-page/VCIS';
import VCIS1 from './Components/VCIS/VCIS-DataCrowd.jsx';
import Monitor from './Components/VCIS/Components/Monitor.jsx';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const shouldRenderNavbar = !location.pathname.includes('/admin') && !location.pathname.includes('/Valoores103A');
  const shouldRenderFooter = !location.pathname.includes('/Valoores103A') && !location.pathname.includes('/PdfViewer');
  const isLoggedIn = true;
  const TITLE = 'Valoores';
  useEffect(() => {
    if (!shouldRenderNavbar && !isLoggedIn) {
      navigate('/admin/Login');
    }
  }, [shouldRenderNavbar, isLoggedIn, navigate]);

  const currentPath = window.location.pathname;

  // const Ip='http://localhost/valoores_front_react/src/Employees';
  const Ip='http://localhost/valoores_back/';

  return (
    <div>
    <>

      {location.pathname.includes('/Valoores103A') && ( 
        <>
          <Menu currentPath={currentPath}/>
          <Routes>
              <Route path="/Valoores103A/" element={<NewHome />} />
              <Route path="/Valoores103A/Industries/" element={<Industries />} />
              <Route path="/Valoores103A/Solutions/" element={<Solutions />} />
              <Route path="/Valoores103A/Consultancy/" element={<Consultancy />} />
              <Route path="/Valoores103A/Solutions/Vfds/" element={<VFDS />} />
              <Route path="/Valoores103A/News&Events" element={<NewsEvents />} />
              <Route path="/Valoores103A/Technology/" element={<Vtech />} />
              <Route path="/Valoores103A/AboutUs/Overview/" element={<AboutUsNew />} />
              {/* <Route path="/Valoores103A/ValooresFramework" element={<ValooresFramework />} /> */}
          </Routes>
          <FooterNew />
        </>
      )}

      {!shouldRenderNavbar && (
        <Routes>
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/admin/Login" element={<Login />} />
          <Route path="/admin/Register" element={<Register />} />
        </Routes>
      )}

      {!shouldRenderFooter && (
        <Routes>
          <Route path="/pdfViewer" element={<Pdf />}></Route>
        </Routes>
      )}

      {shouldRenderNavbar && shouldRenderFooter && (
        <>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>

          <Navbar />          
          {/* <Menu /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/trial" element={<HomeTrial />} />
            <Route path="/test" element={<Test />} />
            <Route path="/business" element={<Business />} />
            <Route path="/ceo" element={<Ceo />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/aboutUs/*" element={<AboutUs />} />
            <Route path="/Geospatial" element={<Geospatial />} />
            <Route path="/Aml" element={<Aml />} />
            <Route path="/News&Events" element={<NewsEvents />} />
            <Route path="/LetterOfCeo" element={<CEO />} />
            <Route path="/Telecommunication" element={<TelecommunicationTest />} />

            <Route path="/Geospatial/retail-data" element={<RetailData />} />
            <Route path="/Geospatial/retail-analytics" element={<RetailAnalytics />} />
            <Route path="/Geospatial/cvs" element={<CVS />} />
            {/* <Route path="/aboutUs/leaders" element={<Leaders />} /> */}
            <Route path="/admin" element={<Admin />} />
            <Route path="/pdfViewer" element={<Pdf />}></Route>
            <Route path="/ai" element={<AI />}></Route>
            {/* <Route path="/banking" element={<Banking />}></Route> */}
            <Route path="/banking" element={<Banking />}></Route>
            <Route path="/btrial" element={<BTrial />}></Route>
            <Route path="/analytics" element={<Analytics />}></Route>
            <Route path="/analytics/*" element={<AnalyticsDisplay />}></Route>
            <Route path="/banking/feature" element={<Feature />}></Route>
            <Route path="banking/riskandcompliance/*" element={<RiskComplianceSub />}></Route>
            <Route path="banking/inProfit/*" element={<InProfit />}></Route>
            <Route path="banking/inDataA/*" element={<InDataA />}></Route>
            <Route path="banking/inMarketing/*" element={<Marketing />}></Route>
            <Route path="banking/inAnalytics/*" element={<InAnalytics />}></Route>
            <Route path="banking/inKernel/*" element={<InKernel />}></Route>
            <Route path="banking/inAsset/*" element={<InAsset />}></Route>
            <Route path="/hr/career" element={<Career />}></Route>
            <Route path="/hr/career/:jobtitle" element={<JobDescriptionPage />}></Route>
            <Route path="/news/pressRelease" element={<PressRelease />}></Route>
            <Route path="/hr/academy" element={<Academy />}></Route>
            <Route path="/clients" element={<Clients />}></Route>
            <Route path="/aboutUs/logo" element={<Logo />}></Route>
            <Route path="/solutions/gov" element={<Gov />}></Route>
            <Route path="/solutions/healthcare" element={<Healthcare />}></Route>
            <Route path="/DareToOvercome" element={<DareToOvercome />}></Route>
            <Route path="/MindMap" element={<MindMap />}></Route>
            <Route path="/Multimedia" element={<Multimedia />}></Route>
            <Route path="/*" element={<NotFound />}></Route>
            <Route path="/Bi" element={<Bi />}></Route>
            <Route path="/Geospatial-Analysis/Case-Managements" element={<CaseManagements/>}></Route>
            <Route path="/Geospatial-Analysis/Case-Geospatial" element={<CaseGeospatial/>}></Route>
            <Route path="/Geospatial-Analysis/Case-Analytics" element={<CaseAnalytics />}></Route>
            <Route path="/Geospatial-Analysis/Case-Biometrics" element={<CaseBiometrics />}></Route>
            <Route path="/Geospatial-Analysis/Case-RiskAndThreatManagement" element={<CaseRiskAndThreatManagement />}></Route>
            <Route path="/Geospatial-Analysis/Case-KYC" element={<CaseKYC />}></Route>
            <Route path="/Geospatial-Analysis" element={<Geospatial />}></Route>
            <Route path="/GeospatialI" element={<GeospatialI />}></Route>
            <Route path="/Digital" element={<Digital />}></Route>
            <Route path="/Insurance" element={<Insurance />}></Route>
            <Route path="/Insurance/overview/*" element={<Overview />}></Route>
            <Route path="/VFDS" element={<ServicesGroup />}></Route>
            <Route path="/Fatf" element={<Fatf />}></Route>
            <Route path="/VCMS" element={<Vcms />}></Route>
            <Route path="/V21" element={<V21 />}></Route>
            <Route path="/TermsAndCondition" element={<TermsAndConditions />}></Route>
            <Route path="/Privacy" element={<PrivacyPolicy />}></Route>
            <Route path="/aboutValoores" element={<AboutValoores />}></Route>
            <Route path="/Employees/:employeeName" element={<EmployeeDetails Ip={Ip}/>} />      
            <Route path="/Vtech/" element={<NVtech />} />     
            {/* <Route path="/NewVtech/" element={<NewVtech />} />      */}
            {/* <Route path="/NVtech/" element={<NVtech />} />      */}
            <Route path="/VCIS" element={<VCIS />} />         
            <Route path="/V-CIS" element={<VCIS1/>} />      

            {/*VCIS COMPONENTS  */}
            {/* <Route path="V-CIS/Monitor" element={<Monitor/>} />    */}


          </Routes>
          <ContactUs Ip={Ip}/>
          {/* <FooterNew /> */}
          <Footer />
        </>
      )}
    </>
    </div>
  );
}

export default App;
