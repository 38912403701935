import {React, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import LoginModal from './modal/LoginModal'; // Adjust path as needed
import service1 from '../../img/VCIS/vcis_service1.jpg';
import service2 from '../../img/VCIS/vcis_service2.jpg';
import service3 from '../../img/VCIS/vcis_service3.jpg';
import service4 from '../../img/VCIS/vcis_service4.jpg';
import service5 from '../../img/VCIS/vcis_service5.jpg';
import service6 from '../../img/VCIS/vcis_service6.jpg';

const Services = () => {

  const [showModal, setShowModal] = useState(false);
  const [pendingLink, setPendingLink] = useState('');
  const navigate = useNavigate();

  const handleLinkClick = (e, link) => {
    const isEligible = localStorage.getItem("isEligible");
    console.log("isEligible:", isEligible); // Debugging line

    if (isEligible === "true") {
      console.log("Eligible to access the link:", link);
      // navigate(link);
    } else {
      e.preventDefault(); // Stop <Link> from navigating
      setPendingLink(link);
      setShowModal(true);
    }
  };

  const handleLoginSuccess = () => {
    // After login, redirect to the previously attempted link
    if (pendingLink) {
      navigate(pendingLink);
      setPendingLink('');
    }
  };

  const service = [
    {
      image: service1,
      title: 'Case managements',
      description: 'involves analyzing data on maps, providing insights into various factors which helps... Read More →',
      link: '../banking/riskandcompliance?variable=Aml'
    },
    {
      image: service2,
      title: 'Geospatial',
      description: 'we have gone beyond basic location data, which simply tells us where things are... Read More →',
      link: '/banking/riskandcompliance?variable=infraud'
    },
    {
      image: service3,
      title: 'Risk and  threat Management ',
      description: 'Both law enforcement and private agencies can benefit from VCIS which enables... Read More →',
      // link: '/banking/riskandcompliance?variable=baselregulatoryVALOORES: Systems'
      link: '/banking/riskandcompliance?variable=baselregulatory'
    },
    {
      image: service4,
      title: 'Analytics',
      description: 'VCIS is an advanced platform that uses AI and machine learning to aid law enforcement... Read More →',
      link: '/banking/feature?page=financialservices'
    },
    {
      image: service5,
      title: 'Biometrics',
      description: 'Protect your data with the highest security Management "audit trail and logs". Read More →',
      link: '/banking/inAnalytics?variable=inVia'
    },
    {
      image: service6,
      title: 'Kyc',
      description: 'Have full power to fight all financial crimes (anti-money laundering, Fraud). Read More →',
      link: '/banking/inAnalytics?variable=inRetail'
    },
  ];
  return (
    <>
      <LoginModal show={showModal} onClose={() => setShowModal(false)} onLoginSuccess={handleLoginSuccess} />

      <div className="services_section" id='services_scroll'>
        <div className="container">
          <div className="row">
            <div className="services__title">
              <h2><span>VCIS </span> Services</h2>
            </div>
            {service.map((serv, index) => (
              <div
                key={index}
                className='col-md-4 p-3'
                onClick={(e) => handleLinkClick(e, serv.link)}
              >
                <div className="img_bg" style={{ backgroundImage: `url(${serv.image})` }}>
                  <div className="overlay__bg"></div>
                  <div className="services__content">
                    <h4 className='text-white'>{serv.title}</h4>
                    <p>{serv.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;