// components/LoginModal.jsx
import React, { useState } from 'react';

const LoginModal = ({ show, onClose, onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  if (!show) return null;

  const handleLogin = async () => {
    setLoading(true);
    setErrorMsg('');

    try {
      const response = await fetch('http://localhost/val_back/controllers/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('isEligible', 'true');
        onLoginSuccess();
        onClose();
      } else {
        setErrorMsg(data.message || 'Invalid credentials');
      }
    } catch (err) {
      setErrorMsg('Server error. Please try again later.');
    }

    setLoading(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content p-4 bg-white rounded shadow-lg" style={{ minWidth: '300px' }}>
        <h4 className="mb-3">Login Required</h4>
        
        <input
          type="text"
          placeholder="Username"
          className="form-control mb-2"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        
        <input
          type="password"
          placeholder="Password"
          className="form-control mb-2"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        
        {errorMsg && <p className="text-danger">{errorMsg}</p>}

        <div className="d-flex justify-content-end">
          <button className="btn btn-secondary me-2" onClick={onClose} disabled={loading}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleLogin} disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
