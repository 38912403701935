import React from "react";
import Carousel from "./Carousel_Vcis";
import Business from "./Business";
import Servicess from "./Services_Vcis";
import Fade from "react-reveal/Fade";
import Nutshell from "./NutShell";
import "./vcis_style.scss";
import VCIS_NutShell from "./VCIS_nutshell";

const VCIS = () => {
  return (
    <>
        <Carousel />
      <Fade up>
        <VCIS_NutShell />
      </Fade>
        <Business />
      <Fade up>
        <Servicess />
      </Fade>
    </>
  );
};

export default VCIS;
