import React from "react";
import NutShell from "./NutShell";
const VCIS_NutShell = () => {
    return (
      <div style={{ backgroundColor: "#f8f9fa", padding: "2rem",boxShadow:'rgb(0 0 0 / 32%) 0px 4px 8px 7px'}} className="container-fluid">
      <div className="nutShell_container">
      <div className="nutShell_container-content col-md-6">
            <span style={{ fontSize: '2rem',padding:'2rem' }}><strong>VCIS</strong> In a Nutshell</span>
            <p style={{ fontSize: '1rem' }} className="mt-2">
                In'Data Crowd harnesses the power of AI and machine learning to provide invaluable data insights.
                With a strong focus on monitoring, investigation, and risk management, this platform serves various lines of business.
                Its primary goal is to create a safer environment by offering its capabilities to law enforcement agencies, private enterprises, and other entities. 
                <br /><br />
                Law enforcement agencies greatly benefit from In'Data Crowd's advanced features, using them for crime investigation, resource management, and emergency response.
                Besides its application in the law enforcement sector, In'Data Crowd is also utilized by private enterprises. 
                One prominent use case is the protection of oil refineries, where the platform offers valuable insights and advanced risk management capabilities. 
                Moreover, In'Data Crowd plays a vital role in the evolution of smart cities, providing essential data insights that contribute to efficient resource allocation, optimized urban planning, and improved overall city management.
                <br /><br />
                The comprehensive suite of tools offered by In'Data Crowd encompasses various functionalities such as knowledge graphs, map explorer, link analysis, case management, and digital investigation features in addition to a risk and threat analysis module.
            </p>
        </div>
        <div className="col-md-6">
            <NutShell />
        </div>
      </div>
      </div>
    );
  };
  
  export default VCIS_NutShell;
  