import React from "react";
import center from "../../img/VCIS/center.png"

const NutShell = () => {
  return (
    <>
      <div
        className="col-md-6 d-flex align-items-center justify-content-center nutShell_principal"
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <img
            src={center}
            className="center_img"
            alt=""
          />
        <div
          id="wheel_of_fortune"
          className="rotate_keyframe rotation_nutshell"
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 364.5 363.1"
            style={{ enableBackground: "new 0 0 364.5 363.1" }}
            xmlSpace="preserve"
          >
            <style type="text/css">
              {`
          .st0{fill:#86CEFA;}
          .st1{fill:#002551;}
          .st2{fill:#5494DA;}
          .st3{fill:#012A66;}
          .st4{fill:#1750AC;}
          .st5{fill:#3373C4;}
          .st6{fill:#003396;}
          .st7{fill:#73B9EE;}
          .st8{fill:#FFFFFF;}
          .st9{font-family:'ArialNarrow';}
          .st10{font-size:8.8371px;}
          .st11{fill:none;}
          .st12{fill:#F8FEFD;}
          .st13{display:none;}
          .st14{display:inline;fill:#001C35;stroke:#FFFFFF;stroke-miterlimit:10;}
          .st15{display:inline;fill:#FFFFFF;}
          .st16{font-size:23.2743px;}
        `}
            </style>
            <g id="Layer_1">
              <path
                className="st0"
                d="M232.6,50.5c-2.1-0.8-4.3-1.6-6.4-2.3c0.2-6.8-1-13.7-3.9-20.3C211.9,4.7,184.8-5.7,161.6,4.6 c-18,8-28.3,26.2-27.2,44.8c-3.2,1.2-6.4,2.5-9.6,3.9l57.2,128.6L232.6,50.5z"
              />
              <path
                className="st1"
                d="M124.9,53.3c-2,0.9-4,1.8-5.9,2.8c-4.6-4.9-10.4-8.9-17.1-11.5C78.2,35.5,51.6,47.3,42.5,71 c-7,18.3-1.5,38.4,12.3,50.7c-1.5,3.1-2.9,6.4-4.1,9.7l131.4,50.5L124.9,53.3z"
              />
              <path
                className="st2"
                d="M313.5,232.3c0.9-2.3,1.7-4.5,2.4-6.8c6.7,0.2,13.5-1.1,20-4c23.2-10.3,33.6-37.5,23.3-60.6 c-8-18-26.2-28.3-44.8-27.2c-1.1-3.1-2.4-6.1-3.7-9.1l-128.6,57.2L313.5,232.3z"
              />
              <path
                className="st3"
                d="M50.7,131.4C50.7,131.4,50.7,131.4,50.7,131.4c-0.8,2.1-1.5,4.1-2.2,6.2c-6.7-0.2-13.4,1.1-19.9,4 C5.4,151.8-5,179,5.3,202.2c7.9,17.9,25.9,28.1,44.3,27.2c1.2,3.3,2.5,6.5,3.9,9.7l128.6-57.2L50.7,131.4z"
              />
              <path
                className="st4"
                d="M131.7,313.3c2.2,0.8,4.4,1.6,6.6,2.4c-0.1,6.5,1.1,13.2,4,19.6c10.3,23.2,37.5,33.6,60.6,23.3 c17.8-7.9,28.1-25.9,27.2-44.3c3.1-1.1,6.2-2.4,9.3-3.8l-57.2-128.6L131.7,313.3z"
              />
              <path
                className="st5"
                d="M309.7,241.4c1.4-2.9,2.7-6,3.8-9c0,0,0,0,0,0l-131.4-50.5l57.2,128.6c2.2-1,4.4-2,6.5-3.1 c4.5,4.8,10.2,8.6,16.7,11.1c23.7,9.1,50.2-2.7,59.3-26.4C329,273.7,323.5,253.7,309.7,241.4z"
              />
              <path
                className="st6"
                d="M53.5,239.1c0.9,2.1,1.9,4.1,2.9,6.1c-4.7,4.5-8.6,10.2-11.1,16.7c-9.1,23.7,2.7,50.2,26.4,59.3 c18.2,7,38.1,1.6,50.5-12c3.1,1.5,6.3,2.8,9.5,4l50.5-131.4L53.5,239.1z"
              />
              <path
                className="st7"
                d="M310.7,124.6c-1-2.1-2-4.3-3-6.4c4.9-4.6,8.9-10.4,11.5-17.1c9.1-23.7-2.7-50.2-26.4-59.3 c-18.5-7.1-38.7-1.5-51,12.6c-3-1.4-6.1-2.7-9.2-3.9l-50.4,131.4L310.7,124.6z"
              />
              <g>
                <text
                  transform="matrix(0.9997 -2.328078e-02 2.328078e-02 0.9997 168.2702 57.7021)"
                  className="st8 st9 st10"
                >
                  KYC
                </text>
              </g>
              <g>
                <text
                  transform="matrix(0.7183 0.6957 -0.6957 0.7183 254.3335 78.855)"
                  className="st8 st9 st10"
                >
                  Map Explorer
                </text>
              </g>
              <text
                transform="matrix(1.518450e-02 0.9999 -0.9999 1.518450e-02 304.5122 148.3413)"
                className="st8 st9 st10"
              >
                Case Management
              </text>
              <text transform="matrix(1.577464e-02 0.9999 -0.9999 1.577464e-02 281.5835 163.1115)">
                <tspan x="0" y="0" className="st8 st9 st10">
                  Evidence
                </tspan>
                <tspan x="-7.3" y="10.6" className="st8 st9 st10">
                  {" "}
                  Management
                </tspan>
              </text>
              <g>
                <text transform="matrix(0.7193 0.6947 -0.6947 0.7193 244.8113 82.8751)">
                  <tspan x="0" y="0" className="st8 st9 st10">
                    Risk and Threat
                  </tspan>
                  <tspan x="12.1" y="10.6" className="st8 st9 st10">
                    Analysis
                  </tspan>
                </text>
              </g>
              <text
                transform="matrix(1.860113e-02 0.9998 -0.9998 1.860113e-02 293.4536 150.112)"
                className="st8 st9 st10"
              >
                Digital investigation
              </text>
              <g>
                <text transform="matrix(0.9999 -1.488079e-02 1.488079e-02 0.9999 161.8375 69.2772)">
                  <tspan x="0" y="0" className="st8 st9 st10">
                    Monitoring
                  </tspan>
                  <tspan x="-6.2" y="10.6" className="st8 st9 st10">
                    &amp; Surveillance
                  </tspan>
                </text>
              </g>
              <text
                transform="matrix(-0.6941 0.7198 -0.7198 -0.6941 269.5079 235.707)"
                className="st8 st9 st10"
              >
                VCIS Services
              </text>
              <text
                transform="matrix(-0.6987 0.7154 -0.7154 -0.6987 275.0683 247.848)"
                className="st8 st9 st10"
              >
                Kernel Tools
              </text>
              <text
                transform="matrix(-0.6984 0.7157 -0.7157 -0.6984 281.132 260.4682)"
                className="st8 st9 st10"
              >
                Integration
              </text>
              <g>
                <text
                  transform="matrix(-0.9996 2.797240e-02 -2.797240e-02 -0.9996 204.323 304.9836)"
                  className="st8 st9 st10"
                >
                  Technology
                </text>
              </g>
              <g>
                <text
                  transform="matrix(-0.9999 1.707516e-02 -1.707516e-02 -0.9999 204.4791 282.6861)"
                  className="st8 st9 st10"
                >
                  Infrastructure
                </text>
              </g>
              <g>
                <text
                  transform="matrix(-0.9998 1.852995e-02 -1.852995e-02 -0.9998 204.9951 293.7166)"
                  className="st8 st9 st10"
                >
                  Link Analysis
                </text>
              </g>
              <g>
                <text
                  transform="matrix(-0.7087 -0.7055 0.7055 -0.7087 122.7771 284.7317)"
                  className="st8 st9 st10"
                >
                  Knowledge graph
                </text>
              </g>
              <g>
                <text
                  transform="matrix(-0.7087 -0.7055 0.7055 -0.7087 133.2197 278.0314)"
                  className="st8 st9 st10"
                >
                  Dynamic Reporting
                </text>
              </g>
              <g>
                <text
                  transform="matrix(-0.7087 -0.7055 0.7055 -0.7087 103.398 282.5612)"
                  className="st8 st9 st10"
                >
                  Big Data
                </text>
              </g>
              <g>
                <text
                  transform="matrix(-1.535688e-02 -0.9999 0.9999 -1.535688e-02 56.6044 195.9166)"
                  className="st8 st9 st10"
                >
                  AI data
                </text>
              </g>
              <g>
                <text
                  transform="matrix(0.7057 -0.7086 0.7086 0.7057 73.1358 113.5419)"
                  className="st8 st9 st10"
                >
                  Clustered Data
                </text>
              </g>
              <g>
                <text transform="matrix(-1.663755e-02 -0.9999 0.9999 -1.663755e-02 69.3727 201.5844)">
                  <tspan x="0" y="0" className="st8 st9 st10">
                    Data Sets
                  </tspan>
                  <tspan x="-3.6" y="10.6" className="st8 st9 st10">
                    Correlations
                  </tspan>
                </text>
              </g>
              <g>
                <text
                  transform="matrix(0.7064 -0.7078 0.7078 0.7064 81.0772 124.2115)"
                  className="st8 st9 st10"
                >
                  Data Governance
                </text>
              </g>

              <g>
                <ellipse
                  transform="matrix(0.9999 -1.329116e-02 1.329116e-02 0.9999 -0.2876 2.3913)"
                  className="st8"
                  cx="179.8"
                  cy="22.8"
                  rx="2.4"
                  ry="3"
                />
                <path
                  className="st8"
                  d="M184.5,29.4c0,1.9-2.1,3.5-4.8,3.6c-2.7,0-4.8-1.5-4.9-3.4c0-1.7,1.7-3.2,4-3.5c0.3,0,0.5-0.1,0.8-0.1
                                c0.2,0,0.4,0,0.7,0C182.6,26.2,184.4,27.6,184.5,29.4z"
                />
                <path
                  className="st8"
                  d="M188.1,31.3c-0.2,0-0.4,0-0.7,0.1L186,30c1-1.4,1.6-3.1,1.6-4.9c0-1.8-0.6-3.4-1.7-4.8l1.3-1.3
                                c0.2,0.1,0.5,0.1,0.8,0.1c1.4,0,2.6-1.2,2.6-2.7c0-1.4-1.2-2.6-2.7-2.6c-1.4,0-2.6,1.2-2.6,2.7c0,0.3,0.1,0.5,0.1,0.8l-1.3,1.4
                                c-1.3-0.9-2.9-1.4-4.6-1.4c-1.8,0-3.4,0.6-4.7,1.6l-1.4-1.3c0.1-0.3,0.1-0.6,0.1-0.9c0-1.4-1.2-2.6-2.7-2.6
                                c-1.4,0-2.6,1.2-2.6,2.7c0,1.4,1.2,2.6,2.7,2.6c0.3,0,0.6-0.1,0.9-0.2l1.3,1.4c-1,1.3-1.5,3-1.5,4.7c0,1.8,0.7,3.5,1.7,4.8
                                l-1.4,1.6c-0.3-0.1-0.5-0.1-0.8-0.1c-1.4,0-2.6,1.2-2.6,2.7c0,1.4,1.2,2.6,2.7,2.6c1.4,0,2.6-1.2,2.6-2.7c0-0.2-0.1-0.5-0.1-0.7
                                l1.5-1.7c1.3,0.9,2.9,1.4,4.6,1.4c1.7,0,3.2-0.6,4.5-1.4l1.4,1.4c-0.1,0.3-0.2,0.6-0.1,0.9c0,1.4,1.2,2.6,2.7,2.6
                                c1.4,0,2.6-1.2,2.6-2.7C190.7,32.5,189.5,31.3,188.1,31.3z M179.7,31.7c-3.6,0-6.6-2.8-6.6-6.4c0-3.6,2.8-6.6,6.4-6.6
                                c3.6,0,6.6,2.8,6.6,6.4C186.2,28.7,183.3,31.7,179.7,31.7z"
                />
              </g>
              <path
                className="st11"
                d="M298.6,71.8c1.1-1.6,1.2-3.2,1.2-3.7C299.7,69.4,299.3,70.7,298.6,71.8z"
              />
              <path
                className="st11"
                d="M298.2,72.2c0.1-0.1,0.1-0.2,0.2-0.3C298.4,72,298.3,72.1,298.2,72.2C298.2,72.2,298.2,72.2,298.2,72.2z"
              />
              <path
                className="st11"
                d="M298.6,71.8c0,0.1-0.1,0.1-0.1,0.2C298.5,71.9,298.5,71.8,298.6,71.8z"
              />
              <path
                className="st8"
                d="M281.5,69.6l1.1,0.7c0.3,0.2,0.6,0.2,0.9,0.1l0.8-0.4c0.6-0.3,1.3,0.1,1.4,0.8l0.1,0.8c0,0.4,0.3,0.8,0.7,1
                            c0.6,0.2,1.2-0.2,1.3-0.8l0.1-0.8c0.1-0.7,0.7-1.1,1.3-0.8l0.1,0c0.5,0.2,1.1,0.1,1.5-0.2l0.6-0.5c0.3-0.2,0.5-0.7,0.4-1.1
                            l-0.3-1.1c-0.2-0.7,0.4-1.3,1.1-1.2l0.7,0.1c0.3,0,0.6-0.1,0.8-0.3l1.2-1.3c-0.7-0.6-1.5-1.1-2.5-1.5c-4.4-1.7-9.3,0.5-11,4.9
                            C281.7,68.6,281.5,69.1,281.5,69.6z M297,75.8c-0.1-0.1-0.2-0.1-0.4-0.1l-0.7-0.1c-0.3,0-0.5-0.2-0.6-0.5l0-0.1
                            c-0.1-0.4-0.4-0.8-0.8-1c-0.4-0.2-0.9-0.1-1.3,0.2l-0.4,0.3c-0.1,0.1-0.2,0.1-0.3,0.1l-1.7,0.2c-0.8,0.1-1.5,0.9-1.4,1.7l0,0.6
                            c0,0.9,0.8,1.5,1.6,1.5l0.1,0c0.4,0,0.8,0.2,1.1,0.6l0,0.1C294.2,78.7,295.9,77.5,297,75.8L297,75.8z M280,67.4
                            c2.1-5.4,8.1-8.1,13.6-6c5.4,2.1,8.1,8.1,6,13.6c-2.1,5.4-8.1,8.1-13.6,6C280.7,78.9,278,72.8,280,67.4z M284.2,73
                            c-0.2,0.3-0.1,0.7,0.2,0.9l1.1,0.8c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9l-1.1-0.8C284.8,72.7,284.4,72.7,284.2,73z
                            M288,73.6c-0.2,0.3-0.1,0.7,0.2,0.9c0.3,0.2,0.7,0.1,0.9-0.2l0.8-1.1c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2L288,73.6z
                            M295.3,67c-0.2-0.3-0.7-0.3-0.9,0l-1.1,1c-0.3,0.2-0.3,0.7,0,0.9c0.2,0.3,0.7,0.3,0.9,0l1.1-1C295.5,67.7,295.5,67.2,295.3,67z"
              />
              <g>
                <path
                  className="st8"
                  d="M322.4,168.4l0.1,3.9l11.6-0.3c1.2-1.4,2.4-2.9,3.3-4L322.4,168.4z"
                />
                <path
                  className="st8"
                  d="M322.6,173.4l0.1,3.9l7.3-0.2c0.6-0.8,1.8-2.3,3.2-4L322.6,173.4z"
                />
                <polygon
                  className="st8"
                  points="322.7,178.7 322.8,182.5 333.1,182.2 330.2,178.5        "
                />

                <rect
                  x="322.9"
                  y="183.9"
                  transform="matrix(0.9996 -2.706840e-02 2.706840e-02 0.9996 -4.9104 8.872)"
                  className="st8"
                  width="4.7"
                  height="3.9"
                />
                <path
                  className="st12"
                  d="M341.1,167.2l-8.9,9.7c-0.4,0.4-0.4,1,0,1.4l2.5,2.9c0.2,0.2,0.2,0.6,0,0.9l-2.5,2.7c-0.3,0.3-0.7,0.3-1,0.1
                                l-1.6-1.2c-0.2-0.1-0.5,0-0.5,0.2l0.1,5.1c0,0.3,0.3,0.6,0.7,0.6l4.9-0.7c0.2,0,0.3-0.3,0.2-0.5l-1-0.9c-0.3-0.3-0.3-0.7,0-1
                                l4.3-4.6c0.2-0.2,0.2-0.6,0-0.9l-2.5-2.6c-0.4-0.4-0.4-1,0-1.4l7-7.7l0.9-0.8c0.1-1-0.7-1.9-1.7-1.9L341.1,167.2z"
                />
              </g>
              <path
                className="st8"
                d="M301.4,289.8c0,0.4-0.2,0.7-0.6,0.8l-2.1,0.8c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.3,0.6-0.4,0.9l1,2
                            c0.2,0.3,0.1,0.7-0.1,1c-0.3,0.4-0.7,0.7-1,1l-0.3,0.2c-0.4,0.3-0.8,0.6-1.2,0.8c-0.3,0.2-0.7,0.1-1-0.1l-1.8-1.4
                            c-0.6,0.2-1.3,0.3-1.9,0.3l-1.2,1.9c-0.2,0.3-0.6,0.5-0.9,0.4c-0.5-0.1-1.1-0.3-1.6-0.5s-1-0.4-1.5-0.7c-0.3-0.2-0.5-0.5-0.4-0.9
                            l0.3-2.2c-0.5-0.5-0.9-1-1.2-1.5l-2.3-0.1c-0.4,0-0.7-0.2-0.8-0.6c-0.2-0.5-0.3-0.9-0.4-1.4l-0.1-0.4c-0.1-0.5-0.1-1-0.1-1.5
                            c0-0.4,0.2-0.7,0.6-0.8l2.1-0.8c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.3-0.6,0.4-0.9l-1-2c-0.2-0.3-0.1-0.7,0.1-1c0.3-0.4,0.7-0.7,1-1
                            l0.3-0.2c0.4-0.3,0.8-0.6,1.2-0.8c0.3-0.2,0.7-0.1,1,0.1l1.8,1.4c0.6-0.2,1.3-0.3,1.9-0.3l1.2-1.9c0.2-0.3,0.6-0.5,0.9-0.4
                            c0.5,0.1,1.1,0.3,1.6,0.5c0.5,0.2,1,0.4,1.5,0.7c0.3,0.2,0.5,0.5,0.4,0.9l-0.3,2.2c0.5,0.5,0.9,1,1.2,1.5l2.3,0.1
                            c0.4,0,0.7,0.2,0.8,0.6c0.2,0.5,0.3,0.9,0.4,1.4l0.1,0.4C301.4,288.8,301.4,289.3,301.4,289.8L301.4,289.8z M290.4,292.6
                            c1.6,0.6,3.4-0.2,4-1.8s-0.2-3.4-1.8-4c-1.6-0.6-3.4,0.2-4,1.8S288.8,292,290.4,292.6z"
              />
              <path
                className="st8"
                d="M182.9,328.3c0-0.5-0.4-0.9-0.9-0.8c-0.5,0-0.9,0.4-0.8,0.9l0,1.4c-1.3,0-2.3,1.1-2.3,2.4l-1.4,0
                            c-0.5,0-0.9,0.4-0.8,0.9s0.4,0.9,0.9,0.8l1.4,0l0,2l-1.4,0c-0.5,0-0.9,0.4-0.8,0.9s0.4,0.9,0.9,0.8l1.4,0l0,2l-1.4,0
                            c-0.5,0-0.9,0.4-0.8,0.9s0.4,0.9,0.9,0.8l1.4,0c0,1.3,1.1,2.3,2.4,2.3l0,1.4c0,0.5,0.4,0.9,0.9,0.8s0.9-0.4,0.8-0.9l0-1.4l2,0
                            l0,1.4c0,0.5,0.4,0.9,0.9,0.8s0.9-0.4,0.8-0.9l0-1.4l2,0l0,1.4c0,0.5,0.4,0.9,0.9,0.8s0.9-0.4,0.8-0.9l0-1.4c1.3,0,2.3-1.1,2.3-2.4
                            l1.4,0c0.5,0,0.9-0.4,0.8-0.9s-0.4-0.9-0.9-0.8l-1.4,0l0-2l1.4,0c0.5,0,0.9-0.4,0.8-0.9s-0.4-0.9-0.9-0.8l-1.4,0l0-2l1.4,0
                            c0.5,0,0.9-0.4,0.8-0.9s-0.4-0.9-0.9-0.8l-1.4,0c0-1.3-1.1-2.3-2.4-2.3l0-1.4c0-0.5-0.4-0.9-0.9-0.8c-0.5,0-0.9,0.4-0.8,0.9l0,1.4
                            l-2,0l0-1.4c0-0.5-0.4-0.9-0.9-0.8c-0.5,0-0.9,0.4-0.8,0.9l0,1.4l-2,0L182.9,328.3z M182.4,332.1l6.9-0.2c0.6,0,1.2,0.5,1.2,1.1
                            l0.2,6.9c0,0.6-0.5,1.2-1.1,1.2l-6.9,0.2c-0.6,0-1.2-0.5-1.2-1.1l-0.2-6.9C181.3,332.6,181.8,332.1,182.4,332.1z M189.4,333.1
                            l-6.9,0.2l0.2,6.9l6.9-0.2L189.4,333.1z"
              />
              <g>
                <path
                  className="st8"
                  d="M75.2,282.2c-0.7-0.7-1.8-0.7-2.5,0l-1.3,1.2c-0.7,0.7-0.7,1.8,0,2.5l7.4,7.5c0.7,0.7,1.8,0.7,2.5,0l1.3-1.2
                                c0.7-0.7,0.7-1.8,0-2.5L75.2,282.2z M79.2,289.1c0.3,0.3,0.3,0.7,0,0.9c-0.3,0.3-0.7,0.3-0.9,0c-0.3-0.3-0.3-0.7,0-0.9
                                C78.5,288.8,79,288.8,79.2,289.1z M79.7,290.5c0.3-0.3,0.7-0.3,0.9,0c0.3,0.3,0.3,0.7,0,0.9c-0.3,0.3-0.7,0.3-0.9,0
                                C79.4,291.1,79.4,290.7,79.7,290.5z M70.2,287.1c-0.7-0.7-1.8-0.7-2.5,0l-1.3,1.2c-0.7,0.7-0.7,1.8,0,2.5l7.4,7.5
                                c0.7,0.7,1.8,0.7,2.5,0l1.3-1.2c0.7-0.7,0.7-1.8,0-2.5L70.2,287.1z M74.2,294c0.3,0.3,0.3,0.7,0,0.9c-0.3,0.3-0.7,0.3-0.9,0
                                c-0.3-0.3-0.3-0.7,0-0.9C73.5,293.8,73.9,293.8,74.2,294z M74.8,295.6c0.3-0.3,0.7-0.3,0.9,0c0.3,0.3,0.3,0.7,0,0.9
                                c-0.3,0.3-0.7,0.3-0.9,0C74.5,296.3,74.5,295.8,74.8,295.6z"
                />
              </g>
              <path
                className="st8"
                d="M24.4,184c1.7-0.3,3.3-0.1,4.7,0.6l3.7-5.5c0.4-0.5,1.1-0.7,1.6-0.3c0.5,0.4,0.7,1.1,0.3,1.6L31,186
                            c1.2,1.1,2,2.5,2.3,4.2c0.8,4.2-2,8.2-6.1,9c-4.2,0.8-8.2-2-9-6.1C17.4,188.8,20.2,184.8,24.4,184z M26.8,195.3l2.3-0.4
                            c0.5-0.1,0.8-0.6,0.7-1s-0.6-0.8-1-0.7l-2.3,0.4c-0.5,0.1-0.8,0.6-0.7,1S26.3,195.4,26.8,195.3z M22.7,193l5.8-1.1
                            c0.5-0.1,0.8-0.6,0.7-1s-0.6-0.8-1-0.7l-5.8,1.1c-0.5,0.1-0.8,0.6-0.7,1S22.3,193.1,22.7,193z M24.5,189.7L28,189
                            c0.5-0.1,0.8-0.6,0.7-1s-0.6-0.8-1-0.7l-3.5,0.7c-0.5,0.1-0.8,0.6-0.7,1S24,189.8,24.5,189.7z"
              />
              <path
                className="st8"
                d="M75,68.5c1.2-0.6,1.8-2.1,1.5-3.4c-0.4-1.6-2.1-2.5-3.7-2.1c-1.6,0.4-2.5,2-2.1,3.6L64.8,71
                            c-0.7-0.4-1.5-0.5-2.3-0.3c-1.6,0.4-2.5,2.1-2.1,3.7c0.4,1.6,2.1,2.5,3.7,2.1c0.4-0.1,0.8-0.3,1.2-0.6l6.5,3.2c0,0.3,0,0.6,0.1,0.9
                            c0.4,1.6,2.1,2.5,3.7,2.1c1.6-0.4,2.5-2.1,2.1-3.7c-0.3-1-1-1.7-1.9-2L75,68.5L75,68.5z M66.3,73.8c0-0.3,0-0.5-0.1-0.8l5.9-4.5
                            c0.2,0.1,0.3,0.2,0.5,0.2l0.7,7.9c-0.2,0.1-0.4,0.2-0.5,0.4L66.3,73.8z"
              />
              <path
                className="st11"
                d="M-229.1-82.3c0.4-1.9,0-3.4-0.2-3.9C-228.8-84.9-228.8-83.6-229.1-82.3z"
              />
              <path
                className="st11"
                d="M-229.2-81.7c0-0.1,0.1-0.2,0.1-0.3C-229.1-82-229.2-81.9-229.2-81.7C-229.2-81.8-229.2-81.7-229.2-81.7z"
              />
              <path
                className="st11"
                d="M-229.1-82.3c0,0.1,0,0.1-0.1,0.2C-229.1-82.1-229.1-82.2-229.1-82.3z"
              />
            </g>
            <g id="Layer_2" className="st13">
              <circle className="st14" cx="182.2" cy="181.9" r="74.1" />
              <text
                transform="matrix(1 0 0 1 177.068 141.2395)"
                className="st15 st9 st16"
              >
                AI
              </text>
              <text
                transform="matrix(1 0 0 1 173.1642 169.1604)"
                className="st15 st9 st16"
              >
                ML
              </text>
              <text
                transform="matrix(1 0 0 1 147.8957 197.0813)"
                className="st15 st9 st16"
              >
                Analytics
              </text>
              <text
                transform="matrix(1 0 0 1 151.5885 225.0022)"
                className="st15 st9 st16"
              >
                Engines
              </text>
              <circle className="st14" cx="-526.7" cy="271.2" r="74.1" />
              <text
                transform="matrix(1 0 0 1 -531.9002 230.5567)"
                className="st15 st9 st16"
              >
                AI
              </text>
              <text
                transform="matrix(1 0 0 1 -535.804 258.4775)"
                className="st15 st9 st16"
              >
                ML
              </text>
              <text
                transform="matrix(1 0 0 1 -561.0726 286.3984)"
                className="st15 st9 st16"
              >
                Analytics
              </text>
              <text
                transform="matrix(1 0 0 1 -557.3797 314.3193)"
                className="st15 st9 st16"
              >
                Engines
              </text>
            </g>
          </svg>
        </div>
        </div>
      </div>
    </>
  );
};
export default NutShell;
