import React from "react";
import { Link } from "react-router-dom";
import Business1 from "../../img/VCIS/business1.png";
import Business2 from "../../img/VCIS/business2.jpg";
import Business3 from "../../img/VCIS/business3.png";
import Business4 from "../../img/VCIS/business4.jpg";
import Business5 from "../../img/VCIS/business5.png";
import Business6 from "../../img/VCIS/business6.jpg";
import Business7 from "../../img/VCIS/vcis_service7.png";
import Business8 from "../../img/VCIS/vcis_service8.png";
import Business9 from "../../img/VCIS/vcis_service9.jpg";
import Business10 from "../../img/VCIS/vcis_service10.png";
import Business11 from "../../img/VCIS/vcis_Service11.jpg";
import Business12 from "../../img/VCIS/vcis_service12.jpg";
import b1 from "../../img/VCIS/vcis_carousel1.jpg";
import Fade from "react-reveal/Fade";

const Business = () => {
  const business = [
    {
      id: 1,
      image: Business1,
      title: "Monitoring and control",
      description:
        "VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/V-CIS/Monitor",
    },
    {
      id: 2,
      image: Business2,
      title: "Risk and  threat Management",
      description:
        "VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/ai",
    },
    {
      id: 3,
      image: Business3,
      title: "Investigation",
      description:
        "VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/solutions/gov",
      // link: '/solutions/gov'
    },
    {
      id: 4,
      image: Business4,
      title: "Location Intelligence",
      description:
        "VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/Telecommunication",
    },
    {
      id: 5,
      image: Business5,
      title: "Law Enforcement - Crime Reduction",
      description:
        " VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/Geospatial",
    },
    {
      id: 6,
      image: Business6,
      title: "Emergency Management ",
      description:
        " VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/Geospatial/retail-data",
    },
  ];
  const business2 = [
    {
      id: 1,
      image: Business7,
      title: "Pipeline Referencing Foundation",
      description:
        "VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/banking",
    },
    {
      id: 2,
      image: Business8,
      title: "Smart City",
      description:
        "VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/ai",
    },
    {
      id: 3,
      image: Business9,
      title: "Government and Education",
      description:
        "VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/solutions/gov",
      // link: '/solutions/gov'
    },
    {
      id: 4,
      image: Business10,
      title: "Communications Data Management",
      description:
        "VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/Telecommunication",
    },
    {
      id: 5,
      image: Business11,
      title: "Special Event Operations",
      description:
        " VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/Geospatial",
    },
    {
      id: 6,
      image: Business12,
      title: "Defence - Resource Management",
      description:
        " VCIS Uses the latest technology enabling our customers and end users to experience the best... ",
      link: "/Geospatial/retail-data",
    },
  ];
  return (
    <>
      <div className="title_business mt-5" id="business_scroll">
        <Fade up>
          <div className="container">
            <div className="row d-flex flex-row mb-3">
              <h2>
                <span>VCIS </span>
                Lines of Business
              </h2>
              <p>
                VCIS provides clean, clear and classy data and unparalleled
                insight and analytics through tools tailored to your workflow so
                you can move from big ideas to big results.
              </p>
              {business.map((slide, index) => (
                <div className="col-md-4 my-4 flex-display" key={index}>
                  <div className="business__card-container text-center">
                    {/* <Link target="_blank" to={slide.link}> */}
                    <img src={slide.image} alt="1" />
                    {/* </Link> */}
                    {/* <Link target="_blank" to={slide.link}> */}
                    <h4 className="fw-bold mb-3">{slide.title}</h4>
                    {/* </Link> */}
                    <p>{slide.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Fade>

        <div
          className="my-5"
          style={{
            height: "500px",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundImage: `url(${b1})`,
          }}
        ></div>

        <Fade up>
          <div className="container">
            <div className="row d-flex flex-row mb-3">
              {business2.map((slide, index) => (
                <div className="col-md-4 my-4 flex-display" key={index}>
                  <div className="business__card-container text-center">
                    {/* <Link target="_blank" to={slide.link}> */}
                    <img src={slide.image} alt="1" />
                    {/* </Link> */}
                    {/* <Link target="_blank" to={slide.link}> */}
                    <h4 className="fw-bold mb-3">{slide.title}</h4>
                    {/* </Link> */}
                    <p>{slide.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
};

export default Business;
